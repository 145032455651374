import React from "react"
import { graphql } from "gatsby"
import SEO from "../components/Seo/"
import MainLayout from "../components/Layout/MainLayout"
import Section from "../components/Layout/Section"
import Playlists from "../components/Playlists"

export const data = graphql`
  query getPlaylistsContent {
    hero: contentfulHeroImages {
      playlists {
        id
        fluid {
          ...GatsbyContentfulFluid_withWebp
        }
      }
    }
    allPlayLists: allContentfulPlaylistYear(
      sort: { fields: year, order: ASC }
    ) {
      playLists: nodes {
        id
        year
        january
        february
        march
        april
        may
        june
        july
        august
        september
        october
        november
        december
      }
    }
  }
`

const PlaylistsPage = ({ location, data }) => {
  const {
    hero: {
      playlists: { fluid },
    },
    allPlayLists: { playLists },
  } = data

  return (
    <MainLayout location={location} imageSrc={fluid} title="Playlists">
      <SEO title="Playlists" />
      <Section title="">
        <Playlists playlists={playLists} />
      </Section>
    </MainLayout>
  )
}

export default PlaylistsPage
