import React, { useState } from "react"
import { makeStyles } from "@material-ui/core/styles"
import { Grid, Typography, Collapse, IconButton } from "@material-ui/core"
import ExpandLessIcon from "@material-ui/icons/ExpandLess"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"

const useStyles = makeStyles(theme => ({
  playlist: {
    marginBottom: "1rem",
  },
  title: {
    marginRight: "0.5rem",
  },
  iconButton: {
    padding: "0",
    color: theme.palette.text.primary,
    opacity: "0.6",
    marginBottom: "1rem",
    transition: "all 0.3s",
    "&:hover": {
      opacity: "1",
    },
  },
  isExpanded: {
    opacity: "1",
  },
}))

const SinglePlaylist = ({ title, tracks }) => {
  const classes = useStyles()
  const [isExpanded, setIsExpanded] = useState(false)
  const toggleExpansion = () => {
    setIsExpanded(!isExpanded)
  }
  return (
    <Grid item xs={12} sm={6} md={4} classes={{ root: classes.playlist }}>
      <IconButton
        disableRipple
        disableTouchRipple
        onClick={toggleExpansion}
        classes={{
          root: `${classes.iconButton} ${isExpanded && classes.isExpanded}`,
        }}
      >
        <Typography
          variant="h5"
          component="h5"
          classes={{ root: classes.title }}
        >
          {title}
        </Typography>
        {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </IconButton>

      <Collapse in={isExpanded} unmountOnExit timeout="auto">
        {tracks.map((track, index) => (
          <Typography key={index}>
            {index + 1}) {track}
          </Typography>
        ))}
      </Collapse>
    </Grid>
  )
}

export default SinglePlaylist
