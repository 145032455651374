import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Typography,
  Grid,
} from "@material-ui/core"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import SinglePlaylist from "../Media/SinglePlaylist"

const useStyles = makeStyles(theme => ({
  expansionPanel: {
    background: theme.palette.secondary.main,
  },
  expandIcon: {
    color: theme.palette.text.primary,
  },
}))

const Playlists = ({ playlists }) => {
  const classes = useStyles()

  return (
    <div style={{ width: "100%" }}>
      {playlists.length > 0 &&
        playlists.map(playList => {
          const months = [
            { title: "Januar", tracks: playList.january },
            { title: "Februar", tracks: playList.february },
            { title: "März", tracks: playList.march },
            { title: "April", tracks: playList.april },
            { title: "Mai", tracks: playList.may },
            { title: "Juni", tracks: playList.june },
            { title: "Juli", tracks: playList.july },
            { title: "August", tracks: playList.august },
            { title: "September", tracks: playList.september },
            { title: "Oktober", tracks: playList.october },
            { title: "November", tracks: playList.november },
            { title: "Dezember", tracks: playList.december },
          ]
          return (
            <ExpansionPanel
              key={playList.id}
              classes={{ root: classes.expansionPanel }}
            >
              <ExpansionPanelSummary
                classes={{ expandIcon: classes.expandIcon }}
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography
                  variant="h4"
                  component="h4"
                  style={{ paddingLeft: "1rem" }}
                >
                  {playList.year}
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails style={{ padding: "2rem" }}>
                <Grid container spacing={5}>
                  {months.map((month, index) => (
                    <SinglePlaylist
                      title={month.title}
                      tracks={month.tracks}
                      key={index}
                    />
                  ))}
                </Grid>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          )
        })}
    </div>
  )
}

export default Playlists
